<script setup lang="ts">
import AppButton from "@/Components/Shared/button/AppButton.vue";
import {IconSize} from "@/Components/Shared/icon/AppIcon.vue";
import {ButtonAlign, ButtonSize, ButtonVariant} from "@/Components/Shared/button";
import {apiPathUrl, isGuest, isVerified, mustBeLogin, mustBeVerified} from "@/Utils/Helpers";
import {useForm} from "@inertiajs/vue3";

type Props = {
    iconSize?: IconSize;
    buttonVariant?: ButtonVariant;
    buttonAlign?: ButtonAlign;
    buttonSize?: ButtonSize | ButtonSize[];
    bookmarked?: number;
    path: string;
    only?: string[];
};

const {
    iconSize = "xsmall",
    buttonVariant = "ghost",
    buttonAlign = "left",
    buttonSize = undefined,
    bookmarked = 0,
    path,
    only = ["posts", "latestPosts", "post", "comments", "decks", "deck"],
} = defineProps<Props>();

const form = useForm({
    content: "",
})

const bookmark = () => {
    if (!path) return;

    if (isGuest.value) {
        mustBeLogin();
        return;
    }

    if (!isVerified.value) {
        mustBeVerified();
        return;
    }

    if (bookmarked) {
        form.delete(`${apiPathUrl(path)}/bookmark`, {
            only,
            preserveScroll: true,
            preserveState: true,
            onSuccess: () => {},
        });
    } else {
        form.post(`${apiPathUrl(path)}/bookmark`, {
            only,
            preserveScroll: true,
            preserveState: true,
            onSuccess: () => {},
        });
    }
};
</script>

<template>
    <AppButton
        :variant="buttonVariant"
        :align="buttonAlign"
        icon="bookmark"
        :icon-size="iconSize"
        :icon-color="bookmarked ? 'primary' : ''"
        :size="buttonSize"
        :key="bookmarked"
        :loading="form.processing"
        @click="bookmark"
    >
        {{ bookmarked ? 'Opgeslagen' : 'Opslaan' }}
    </AppButton>
</template>
